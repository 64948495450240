@import "./_env.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  position: fixed;
  background: $tertiary-color;
  color: $black-color;
  width: 750px;
  height: auto;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}
.modal-text {
  text-align: center;
  font-size: 18px;
}
.modal-btns-div {
  display: flex;
  justify-content: space-around;
}
