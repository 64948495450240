@import "./_env.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-select-main {
  border-radius: 5px;
  position: fixed;
  background: $tertiary-color;
  color: black;
  width: 680px;
  max-height: 800px;
  height: auto;
  overflow-y: auto;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 10px 20px 10px;
}
.modal-text {
  margin-bottom: 10px;
  padding: 0;
  text-align: center;
}
.modal-select-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-select {
  background-color: $tertiary-dark-color;
  color: $white-color;
  padding: 10px;
  width: 80%;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
  &-btns-div {
    margin: 12px 0;
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}
