@import "./_env.scss";
//btns
button {
  cursor: pointer;
}

.btn-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 30px;
  border-radius: 30px;
  border: 0px;
  margin: 0px 0px;
  padding: 13px 30px;
  width: 149px;
  height: 42px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: $secondary-color;
  background: $primary-color;
  &:hover {
    background: $tertiary-variant-color;
    border: 2px solid $primary-color;
    color: $secondary-color;
  }
}

.btn-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 30px;
  box-sizing: border-box;
  border: 0px;
  border-radius: 30px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  padding: 16px 30px;
  width: 149px;
  height: 48px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: $primary-color;
  background-color: $secondary-color;
  &:hover {
    color: $secondary-color;
    background: $tertiary-variant-color;
    border: 2px solid $secondary-color;
  }
  &:disabled {
    border: 0px;
  }
}

.btn-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 30px;
  box-sizing: border-box;
  border: 0px;
  border-radius: 30px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  padding: 16px 30px;
  width: 149px;
  height: 48px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: $white-color;
  background-color: $primary-color;
  cursor: pointer;
  &:hover {
    color: $primary-color;
    background: $secondary-color;
  }
  &:disabled {
    color: $black-color;
    background-color: $tertiary-color;
    cursor: not-allowed;
    border: 0px;
  }
}
//btn respuestas

.btn-response-left {
  width: 143px;
  height: 42px;
  background: $white-color;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 60px 0px 0px 60px;
  color: $tertiary-dark-color;
  font-weight: bold;
  &:active {
    background: $primary-color;
  }
  &:hover {
    border: 1px solid $tertiary-variant-color;
  }
  &-pressed {
    background: $primary-color;
  }
}
.btn-response-center {
  width: 143px;
  height: 42px;
  background: $white-color;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: $tertiary-dark-color;
  font-weight: bold;
  &:active {
    background: $primary-color;
  }
  &:hover {
    border: 1px solid $tertiary-variant-color;
  }
  &-pressed {
    background: $primary-color;
  }
}
.btn-response-right {
  width: 143px;
  height: 42px;
  background: $white-color;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 0px 60px 60px 0px;
  color: $tertiary-dark-color;
  font-weight: bold;
  &:active {
    background: $primary-color;
  }
  &:hover {
    border: 1px solid $tertiary-variant-color;
  }
  &-pressed {
    background: $primary-color;
  }
}

.btn-options-quiz {
  width: 285px;
  height: 48px;
  margin: 0px 10px;
  &:disabled {
    background: $tertiary-color;
    color: $black-color;
    border: 0px;
    cursor: default;
  }
}
