@import "./buttons.scss";
@import "./_env.scss";
@import "./links.scss";
@import "./questions.scss";
@import "./texts.scss";

* {
  margin: 0;
  padding: 0;
  font-family: "brandon" !important;
}
.main-div {
  width: 100%;
  height: 100%;
}

textarea:focus,
input:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-background-clip: text;
  -webkit-text-fill-color: $white-color;
}
::placeholder {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $white-color;
}