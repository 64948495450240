@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;400&family=Lato&display=swap");
@import "./_env.scss";
//text format:
h1 {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 97px;
  line-height: 97px;
  letter-spacing: -1.5px;
  color: $black-stroke-color;
  margin: 0;
}
h2 {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 72px;
  letter-spacing: -0.5px;
  color: $black-stroke-color;
}
h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 46px;
  line-height: 56px;
  color: $black-stroke-color;
}
h4 {
  font-family: "Josefin Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;
  color: $black-stroke-color;
}
h5 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 42px;
  color: $black-stroke-color;
}

.text-subtitle-1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: $black-stroke-color;
}
.text-subtitle-2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: $black-stroke-color;
  color: $tertiary-color;
}
.text-subtitle-3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: $black-stroke-color;
}
.text-body-1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: $black-stroke-color;
}
.text-body-2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.75px;
  color: $black-stroke-color;
}
.text-body-3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.75px;
  color: $secondary-color;
}

.text-btn {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
.text-caption {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: $black-stroke-color;
}
.text-overline {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: $black-stroke-color;
}
