@import "./_env.scss";
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 25px;
  height: 25px;
  border: 10px solid $secondary-color;
  border-top: 10px solid $primary-color;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 42px;
  // margin-left: 100px;
}
