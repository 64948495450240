@font-face {
  font-family: "brandon";
  src: local("brandon"),
    url("../fonts/brandon/HVD-Fonts-BrandonText-Regular.otf") format("truetype");
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
