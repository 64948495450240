@import "./_env.scss";

.questions {
  // background: $primary;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 60px;
  cursor: pointer;
  color: $tertiary-dark-color;
  &:hover {
    background: $hover-color;
    box-shadow: inset 0px 0px 4px 1px rgba(107, 8, 44, 0.1);
  }
  &-selected {
    background: $active-color;
    box-shadow: inset 0px 0px 4px 1px rgba(107, 8, 44, 0.1);
  }
  &:active {
    background: $hover-color;
    box-shadow: inset 0px 0px 4px 1px rgba(107, 8, 44, 0.1);
  }
}
