@import "./_env.scss";

.navbar-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: $tertiary-dark-color !important;
  color: $primary-color;
}
.img-logo {
  width: 183px;
  height: 46px;
  margin: 24px 36px;
}
.img-company {
  width: 150px;
  height: 50px;
}
.user-info-div {
  display: flex;
  margin-right: 30px;
  align-items: center;
}
.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
}
