@import './_env.scss';

hr{
  border: 0px;
  border-bottom: 3px solid $primary-color;
  width: 70%;
}
.hr-div{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white-color;
}
.quiz-form-div{
  background-color: $tertiary-color;
  min-height: 100vh;
  padding-top: 40px;
  padding-left: 20px;
}