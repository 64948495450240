
$primary-color: rgb(255 240 0);
$secondary-color: rgb(173 173 173);
$tertiary-color: rgb(238 238 240);
$tertiary-dark-color: rgb(64, 64, 64);
$tertiary-variant-color: rgb(117, 117, 117);
$active-color: rgb(255 240 0);
$hover-color: rgb(255,240,0);
$black-color: rgb(0 0 0);
$black-stroke-color: rgba(0, 0, 0, 0.87);
$white-color: rgb(255 255 255);
