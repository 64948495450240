@import './_env.scss';


.groups-div{
  min-width: 310px;
  min-height: 100vh;
  height: calc(100% - 60px);
  padding-bottom: 40px;
}
.groups-title-div{
  width: 275px;
  padding-left: 20px;
}
.groups-title{
  padding-top: 45px;
  color: $white-color;
}
.subtitle{
  width: 220px;
  margin: 20px 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid $primary-color;
}
.empty-div{
  width: 40px;
}
