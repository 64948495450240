@import "./_env.scss";
@import "./quiz-form-questions.scss";

.quiz-form-options-radio-input {
  &-div {
    display: flex;
    flex-direction: column;
  }
  &-item {
    display: flex;
    align-items: center;
    border: 1px solid $tertiary-variant-color;
    background-color: $tertiary-color;
    margin-bottom: 10px;
    padding-left: 15px;
    &:hover {
      border: 1px solid $tertiary-variant-color;
    }
    &-pressed {
      background-color: $primary-color;
      border: 1px solid $tertiary-variant-color;
    }
  }
  &-label {
    padding: 15px;
    padding-left: 0px;
    margin: 0px 40px;
    cursor: pointer;
    width: 100%;
  }
}
input {
  cursor: pointer;
}
